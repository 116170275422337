import { Sprinkles } from '../../styles/sprinkles.css';

export interface ThreeLineTextSectionSettings {
  contentType: 'threeLineText';
  props: ThreeLineTextSectionProps;
}

export interface ThreeLineTextSectionProps {

}

export const defaultThreeLineTextSectionProps: ThreeLineTextSectionProps = {

};
