export interface MoveImageSectionSettings {
  contentType: 'moveImage';
  props: MoveImageSectionProps;
}

export interface MoveImageSectionProps {

}

export const defaultMoveImageSectionProps: MoveImageSectionProps = {

};
