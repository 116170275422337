import { Sprinkles } from '../../styles/sprinkles.css';

export interface NormalFooterSettings {
  footerType: 'normalFooter';
  props: NormalFooterProps;
}

export interface NormalFooterProps {

}

export const defaultNormalFooterProps: NormalFooterProps = {

};
