export interface FloatHeaderSettings {
  headerType: 'floatHeader';
  props: FloatHeaderProps;
}

export interface FloatHeaderProps {

}

export const defaultFloatHeaderProps: FloatHeaderProps = {

};
