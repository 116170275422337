import { Sprinkles } from '../../styles/sprinkles.css';

export interface TableLayoutSectionSettings {
  contentType: 'tableLayout';
  props: TableLayoutSectionProps;
}

export interface TableLayoutSectionProps {

}

export const defaultTableLayoutSectionProps: TableLayoutSectionProps = {

};
