export interface MovieHeroSettings {
  topType: 'movie';
  props: MovieHeroProps;
}

export interface MovieHeroProps {

}

export const defaultMovieHeroProps: MovieHeroProps = {

};
